<template>
  <div>
    <div class="inline">
      <BaseSelect
        :value="form.minRank.value"
        :label="$t('players.min-rank')"
        :options="rankList"
        :error-message="form.minRank.error"
        :required="true"
        class="indent settings-control"
        @input="
          updateFormData({ name: form.minRank.name, value: $event })
        "
      />
      <pvp-tooltip class="left">
        <Icon name="info" inline />
        <template slot="tooltip">
          {{ $t('tournaments.tooltips_settings_wf-min-rank') }}
        </template>
      </pvp-tooltip>
    </div>

    <BaseSwitcher
      v-if="abilities.canEditNeedRatingRankRestriction"
      :value="form.needRatingRankRestriction.value"
      @input="
        updateFormData({
          name: form.needRatingRankRestriction.name,
          value: $event,
        })
      "
    >
      <template slot="label">
        {{ $t('tournaments.wf_leagues_available') }}
        <pvp-tooltip class="info">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_leagues_wf') }}
          </template>
        </pvp-tooltip>
      </template>
    </BaseSwitcher>

    <div v-if="form.needRatingRankRestriction.value" class="indent">
      <div class="leagues-label">
        {{ $t('tournaments.wf_leagues_list') }}
      </div>
      <BaseSelect
        class="settings-control"
        :value="form.ratingRankRestriction.value"
        :disabled="!abilities.canEditNeedRatingRankRestriction"
        :placeholder="$t('tournaments.select_leagua_placeholder')"
        list-position="bottom"
        :multiple="true"
        :limit="7"
        value-key="value"
        label-key="label"
        :options="leagues"
        @input="
          updateFormData({
            name: form.ratingRankRestriction.name,
            value: $event,
          })
        "
      />
    </div>

    <div>
      <BaseSwitcher
        :value="form.checkGameProfileRegion.value"
        :error-message="form.checkGameProfileRegion.error"
        :disabled="!abilities.canEditCheckGameProfileRegion"
        @input="
          updateFormData({
            name: form.checkGameProfileRegion.name,
            value: $event,
          })
        "
      >
        <template slot="label">
          {{ $t('games.server') }}
          <pvp-tooltip class="info">
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_wf-server') }}
            </template>
          </pvp-tooltip>
        </template>
      </BaseSwitcher>
    </div>

    <BaseSelect
      v-if="form.checkGameProfileRegion.value"
      class="settings-control"
      :value="form.regionCode.value"
      :label="$t('games.server')"
      :disabled="!abilities.canEditRegionCode"
      :error-message="form.regionCode.error"
      :options="regionData"
      value-key="code"
      label-key="label"
      :required="true"
      @input="
        updateFormData({ name: form.regionCode.name, value: $event })
      "
    />

    <BaseSwitcher
      v-if="form.pickBanSystem"
      :value="form.pickBanSystem.value"
      :disabled="!form.checkinMatchEnable.value"
      :error-message="form.pickBanSystem.error"
      @input="
        updateFormData({
          name: form.pickBanSystem.name,
          value: $event,
        })
      "
    >
      <template slot="label">
        {{ $t('matches.wf_map_picking') }}
        <pvp-tooltip class="info">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-maps') }}
          </template>
        </pvp-tooltip>
      </template>
    </BaseSwitcher>

    <template v-if="form.pickBanSystem && form.pickBanSystem.value">
      <div v-if="form.pickBanTimeout" class="inline">
        <BaseSelect
          :value="form.pickBanTimeout.value"
          class="settings-control"
          :label="$t('matches.wf_map_picking-time')"
          :options="pickbanTimeoutsList"
          :error-message="form.pickBanTimeout.error"
          :required="true"
          @input="
            updateFormData({
              name: form.pickBanTimeout.name,
              value: $event,
            })
          "
        >
          <template v-slot:option="{ option }">
            {{ $tc('date.seconds_pluralization', option) }}
          </template>
          <template v-slot:selected-option="{ option }">
            {{ $tc('date.seconds_pluralization', option) }}
          </template>
        </BaseSelect>
        <pvp-tooltip class="left">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-pick-time') }}
          </template>
        </pvp-tooltip>
      </div>

      <div v-if="form.gameMaps" class="maps indent">
        <div class="maps-label">
          {{ $t('matches.wf_map_list') }}
          <pvp-tooltip class="left">
            <Icon name="info" inline />
            <template slot="tooltip">
              {{ $t('tournaments.tooltips_settings_wf-maps-list') }}
            </template>
          </pvp-tooltip>
        </div>
        <BaseSelect
          v-if="maps.length"
          class="settings-control"
          :value="form.gameMaps.value"
          :placeholder="$t('games.select_map')"
          list-position="bottom"
          :multiple="true"
          :limit="7"
          :error-message="form.gameMaps.error"
          value-key="code"
          label-key="name"
          :options="maps"
          @input="
            updateFormData({
              name: form.gameMaps.name,
              value: $event,
            })
          "
        />
      </div>

      <div v-if="form.gameType" class="inline">
        <tournament-format-selector
          :value="form.gameType.value"
          :disabled="!form.pickBanSystem.value"
          :error-message="form.gameType.error"
          class="indent settings-control"
          @input="
            updateFormData({
              name: form.gameType.name,
              value: $event,
            })
          "
        />
        <pvp-tooltip class="left">
          <Icon name="info" inline />
          <template slot="tooltip">
            {{ $t('tournaments.tooltips_settings_wf-best-of') }}
          </template>
        </pvp-tooltip>
      </div>

      <div
        v-if="
          form.lastMapPickSideFormat &&
          form.gameType &&
          form.gameType.value !== 2
        "
        class="inline"
      >
        <BaseSelect
          class="settings-control"
          :value="form.lastMapPickSideFormat.value"
          :label="$t('games.last_map_pick_side_label')"
          :error-message="form.lastMapPickSideFormat.error"
          :options="lastMapPickSide"
          :required="true"
          @input="
            updateFormData({
              name: form.lastMapPickSideFormat.name,
              value: $event,
            })
          "
        >
          <template v-slot:option="{ option }">
            {{ $t(`games.last_map_pick_side_${option}`) }}
          </template>
          <template v-slot:selected-option="{ option }">
            {{ $t(`games.last_map_pick_side_${option}`) }}
          </template>
        </BaseSelect>
      </div>
    </template>
  </div>
</template>

<script>
import TournamentFormatSelector from '@components/TournamentComponents/TournamentFormatSelector.vue';
import { i18n } from '@src/localization/config';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSwitcher from '@components/BaseComponents/Form/Switcher.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'WfSettings',
  components: {
    TournamentFormatSelector,
    BaseSwitcher,
    BaseSelect,
    Icon,
  },
  data: () => ({
    maps: [],
  }),
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    ...mapState('application', ['pickbanTimeoutsList']),
    ...mapGetters('application', ['getWfShards']),

    leagues() {
      return this.form?.ratingRankRestriction?.default.map(
        (league) => ({
          value: league,
          label: i18n.t(`tournaments.${league}`),
        }),
      );
    },

    rankList() {
      return [0, 20, 26, 60, 100];
    },

    lastMapPickSide() {
      return [0, 1];
    },

    regionData() {
      return this.getWfShards;
    },
  },
  created() {
    this.fetchMapList(this.form.idGame.value).then(({ maps }) => {
      this.maps = maps;
    });
  },
  methods: {
    ...mapActions('tournamentSettings', ['updateFormData']),
    ...mapActions('matches', ['fetchMapList']),
  },
};
</script>

<style lang="scss" scoped>
.switcher {
  margin-top: 26px;
}

.select,
.indent {
  margin-top: 20px;
}

.maps {
  .select {
    margin-top: 4px;
  }

  &-label {
    color: rgba(white, 0.5);
    font-size: em(14px);

    .tt-wrapper {
      vertical-align: middle;
    }
  }
}

.inline {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 420px;

  .select {
    width: 100%;
  }

  .icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-bottom: 2px;
    font-size: 18px;
  }
}

.info {
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;
  }
}
.settings-control {
  max-width: 420px;
}
</style>
